export function isEditingBuilderDoc() {
  return !!document.querySelector('[data-builder-doc-editing=true]')
}

export const getPreviewIframe = () =>
  document.querySelector('iframe.js-preview-frame')

export const isPreviewIframe = () =>
  window.frameElement?.classList?.contains('js-preview-frame')

export const getPreviewIframeDoc = () => getPreviewIframe().contentDocument

export const dispatchEventToPreview = (eventName, detail) => {
  const event = new CustomEvent(eventName, { detail: detail })
  getPreviewIframeDoc().dispatchEvent(event)
}

export const getNodeInPreviewIframe = (nodeId) =>
  getPreviewIframeDoc().querySelector(`[data-node-id=${nodeId}]`)

export const getNodeInNodeTree = (nodeId) =>
  document.querySelector(`[data-node-id=${nodeId}]`)

/* Returns the iframe element in the parent document that contains the document this function was called from */
export const getIframeInParentDoc = () => {
  if (!window.parent || window.parent == window)
    throw new Error('Can not locate parent window')

  const iframe = Array.from(
    window.parent.document.querySelectorAll('iframe')
  ).find((iframe) => iframe.contentDocument == document)

  if (!iframe) throw new Error('Can not locate iframe container')

  return iframe
}

/* Will return true if the element passed is the last added node. False otherwise. It's assumed you are calling it from the preview iframe context (for now) */
export const isLastAddedNode = (element) => {
  const lastAddedNodeId = window.frameElement?.dataset?.lastAddedNodeId
  if (!lastAddedNodeId) return false

  const node = element.closest('.js-builder-node')
  const currentNodeId = node.dataset.nodeId

  return lastAddedNodeId === currentNodeId
}

export const iframeIsLoaded = (iframe) => {
  const url = iframe.contentDocument.location.href
  const readyState = iframe.contentDocument.readyState
  return readyState === 'complete' && url && url !== 'about:blank'
}
